import React from 'react';
import '../css/stylesHome.css';

/*const WhatsAppButton = () => {*/
const WhatsAppButton = ({ showScrollBtn, isButtonAbsolute }) => {
  if (!showScrollBtn) {
    return null;
  }




    return (
      /*<div className="wsp-button fixed ">*/
      <div className="wsp-button" style={{
        position: isButtonAbsolute ? 'absolute' : 'fixed',
        bottom: isButtonAbsolute ? '20px' : '15px',
        right: '10px'
      }}>
        <a 
          href="https://wa.me/+56984058436" 
          target="_blank" 
          rel="noopener noreferrer"
          className="bg-verde hover:bg-amarillo text-white rounded-full shadow-lg p-4 flex items-center justify-center hover:scale-110 transform transition-transform duration-300"
          style={{ width: '48px', height: '48px'}}
        >
          <i className="fab fa-whatsapp h-9.5 w-6 text-gray-900" style={{ fontSize: '36px'}}></i>
        </a>
      </div>
    );
  }
  
  export default WhatsAppButton;
