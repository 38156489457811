import React from 'react';
import { Link } from 'react-router-dom';
import '../css/stylesFooter.css';

const Footer = () => {
  return (
    <footer className='bg-amarillo relative'   >
      <div className="custom-shape-divider-top-1698008434">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
        </svg>
      </div>

      <div className="footer px-10 py-16 text-negro mx-auto max-w-7xl ">
        <nav className='pl-10'>
          <header className="footer-title text-lg text-negro opacity-80"><Link to="/" className="">ElectroRiders</Link></header>
          <Link to="/nosotros" className="link link-hover text-base">Nosotros</Link>
          <Link className="link link-hover text-base">Servicios</Link>
          <Link to="/conversiones" className="link link-hover ml-2 text-sm">Conversiones</Link>
          <Link to="/asistencia" className="link link-hover ml-2 text-sm">Asistencia/Ventas</Link>
          <Link to="/contacto" className="link link-hover text-base">Contacto</Link>
        </nav>
        <nav className='pl-10'>
          <header className="footer-title text-lg text-negro opacity-90">Contáctanos</header>
          <h1 className='text-base'>Ariel Nicolás Echeverría</h1>
          <a href="https://wa.me/56984058436" target="_blank" rel="noopener noreferrer" className="text-base link link-hover">+56 984058436</a> {/* eslint-disable-next-line */}
          <a className="link link-hover text-base" href="mailto:contacto@electroriders.cl">contacto@electroriders.cl</a>
        </nav>
        <nav className='pl-10'>
          <header className="footer-title text-negro opacity-90 text-lg">Redes Sociales</header>
          <div className="grid grid-flow-col gap-4">
            <a className="social-icon" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/electro.riders">
              <i className="fab fa-instagram"></i>
            </a>
            <a className="social-icon" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@ElectroRidersChile">
              <i className="fab fa-youtube"></i>
            </a>
            <a className="social-icon" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/electroriders">
              <i className="fab fa-linkedin"></i>
            </a>
            <a className="social-icon" target="_blank" rel="noopener noreferrer" href="https://wa.me/+56984058436">
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </nav>
      </div>

      <div className="footer footer-center p-4 bg-negro text-base-content">
        <aside>
          <p>Electro Riders © {new Date().getFullYear()} Desarrollado por
            <a href="https://www.vertibox.cl" target="_blank" rel="noopener noreferrer">
              <span style={{ color: '#02c7be' }}> Verti</span>
              <span style={{ color: '#9065e7' }}>Box</span>
            </a>
          </p>
        </aside>
      </div>
    </footer>
  );
};

export default Footer;