import React, { useState, useEffect } from 'react';
import Header from '../base/Header';
import Footer from '../base/Footer';
import logoYellow from "../../assets/img/logoER-YW.png";
import moto_conversion from "../../assets/img/moto_conversion.png";
import kart_conversion from "../../assets/img/kart_conversion.png";
import motor_borda1 from "../../assets/img/motor_borda1.png";
import motor_borda2 from "../../assets/img/motor_borda2.png";
import baterias from "../../assets/img/baterias.png";

import { Link } from 'react-router-dom';
import '../css/stylesHero.css';
import '../css/stylesHome.css';
import '../css/stylesConversiones.css';
//AOS (Animate On Scroll)
import AOS from "aos";
import "aos/dist/aos.css";
import WhatsAppButton from '../base/Wsp';


function Home() {
  const [showScrollBtn, setShowScrollBtn] = useState(false);
  const [isButtonAbsolute, setIsButtonAbsolute] = useState(false);

  const scrollToNextSection = () => {
    const nextSectionElement = document.querySelector('.next-section');
    nextSectionElement.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowScrollBtn(true);
      } else {
        setShowScrollBtn(false);
      }
  
      const footerTop = document.querySelector('footer').getBoundingClientRect().top;
      if (footerTop < window.innerHeight) {
        setIsButtonAbsolute(true);
      } else {
        setIsButtonAbsolute(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.init({duration:1200})
  })
  

  return (
    <>
    


    {/* INICIO CONTENIDO */}
    
    <div className='container max-w-7xl mx-auto next-section'>

      <div><Header /></div>

      
      <div className='py-14 md:py-20 md:px-20  border-slate-700'>

        
        <div >
          {/* SECCION INTRODUCCION*/}
          <div className="flex flex-col md:flex-row items-center p-0">
            <div className="w-full md:w-1/2 text-left" data-aos="fade-left">
              <h1 className='text-neutral-100 font-bold text-3xl md:text-3xl md:text-left text-center md:px-2 py-5 '>
                <span className='font-bold text-4xl md:text-5xl'>Incorpórate</span><br />
                al mundo de la <span className='text-amarillo text-4xl md:text-4xl'>Electromovilidad</span>
              </h1>
            </div>
            <div className="w-full md:w-1/2 text-right" data-aos="fade-up">
                <p className='text-center max-w-lg py-4 px-8 md:px-4 text-neutral-300 text-base mx-auto'>
                    Súmate a la revolución de la electromovilidad <span className='text-verde'>transformando</span> tus vehículos y motores a gasolina a sistemas eléctricos de alto rendimiento. Al realizar esta conversión, no sólo contribuirás a la <span className='text-verde'>reducción de emisiones contaminantes</span>, sino que también optimizarás la <span className='text-verde'>eficiencia energética</span> de tus vehículos.
                </p> 
                <p className='text-center max-w-lg py-4 px-4 text-neutral-300 text-base mx-auto'>
                    Los sistemas eléctricos ofrecen un <span className='text-verde'>rendimiento superior, menor mantenimiento y un ahorro significativo</span>  en costos operativos a largo plazo. Únete al cambio y disfruta de una movilidad más limpia, eficiente y sostenible para el futuro.
                </p> 
            </div>
          </div>
          {/*FIN SECCION INTRODUCCION*/}

          {/* SECCION MOTOS */}
          <div className='py-10 px-4'>

            <h1 className='text-center text-neutral-100 font-bold text-4xl md:py-10' data-aos="fade-right">
              Conversión de <span className='font-bold text-5xl text-amarillo'>Motocicletas</span></h1>

            <div className="max-w-6xl mx-auto sm:flex items-center pt-4">

              {/*Columna 1*/}
              <div className="sm:w-2/5">
                {/*Descripción*/}
                <div>
                  <p className='text-center max-w-lg mx-auto py-8 px-8 text-neutral-300 text-base' data-aos="fade-up"> La conversión de <span className='text-amarillo'>ElectroRiders</span> transforma motocicletas de gasolina en vehículos eléctricos de alto rendimiento, optimizados para la eficiencia energética y la reducción de emisiones.</p>
                </div>
                {/*Imagen*/}
                <div className="px-10 md:px-5">
                  <img src={moto_conversion} alt="Album" className="mt-4 w-full md:w-200 hover:scale" data-aos="zoom-in" />
                </div>
                {/*Botón Cotizar Laptop*/}
                <div className="justify-center hidden md:flex">
                  <Link to="/contacto">
                    <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                      Cotiza Aquí
                    </button>
                  </Link>
                </div>
              </div>

              {/*Columna 2*/}
              <div className="sm:w-3/5 ">
                {/*Especificaciones*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Especificaciones</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300 text-base" data-aos="fade-up">
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Motor Eléctrico:</span> <span className="value">20 kW de potencia.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Batería:</span> <span className="value">Litio-ion, 4.5 kWh capacidad total.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Autonomía:</span> <span className="value">Hasta 4 horas por carga completa.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Tiempo de Carga:</span> <span className="value">3 horas (cargador rápido), 6 horas (cargador estándar).</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Velocidad Máxima:</span> <span className="value">90 km/h.</span>
                    </li>
                  </ul>
                </div>

                {/*Componentes*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Componentes Incluídos</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Batería</span> 
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Controlador de Motor eléctrico</span> 
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Cargador</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Motor BLDC</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Instrumentación digital con indicadores de estado de carga y autonomía</span>
                    </li>
                  </ul>
                </div>

                {/*Rendimiento*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Rendimiento</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">80% menos emisiones comparado con el modelo original de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción del costo operativo en un 70% respecto al modelo de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción en frecuencia y costo de mantenimiento en comparación con las motocicletas de combustión.</span>
                    </li>
                  </ul>

                  {/*Botón Cotizar Móvil*/}
                  <div className="flex justify-center md:hidden">
                    <Link to="/contacto">
                      <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                        Cotiza Aquí
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*FIN SECCION MOTOS*/}    

          {/* SECCION KARTS */}
          <div className='py-10 px-4'>

            <h1 className='text-center text-neutral-100 font-bold text-4xl md:py-10' data-aos="fade-right">
              Conversión de <span className='font-bold text-5xl text-amarillo'>Karts</span></h1>

            <div className="max-w-6xl mx-auto sm:flex items-center pt-4">

              {/*Columna 1*/}
              <div className="sm:w-2/5">
                {/*Descripción*/}
                <div>
                  <p className='text-center max-w-lg mx-auto py-8 px-8 text-neutral-300 text-base' data-aos="fade-up"> ElectroRiders convierte karts de gasolina en karts eléctricos de alto rendimiento, optimizados para la eficiencia energética y la reducción de emisiones.</p>
                </div>
                {/*Imagen*/}
                <div className="px-10 md:px-5">
                  <img src={kart_conversion} alt="Album" className="w-full md:w-200 hover:scale-105 transition-transform duration-300" data-aos="zoom-in" />
                </div>
                {/*Botón Cotizar Laptop*/}
                <div className="justify-center hidden md:flex">
                  <Link to="/contacto">
                    <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                      Cotiza Aquí
                    </button>
                  </Link>
                </div>
              </div>

              {/*Columna 2*/}
              <div className="sm:w-3/5 ">
                {/*Especificaciones*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Especificaciones</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300 text-base" data-aos="fade-up">
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Motor Eléctrico:</span> <span className="value">20 kW de potencia.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Batería:</span> <span className="value">Litio-ion, 4.5 kWh capacidad total.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Autonomía:</span> <span className="value">Hasta 2 horas por carga completa.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Tiempo de Carga:</span> <span className="value">3 horas (cargador rápido), 6 horas (cargador estándar).</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Velocidad Máxima:</span> <span className="value">80 km/h.</span>
                    </li>
                  </ul>
                </div>

                {/*Componentes*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Componentes Incluídos</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Batería</span> 
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Controlador de Motor eléctrico</span> 
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Cargador</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Motor BLDC</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Instrumentación digital con indicadores de estado de carga y autonomía</span>
                    </li>
                  </ul>
                </div>

                {/*Rendimiento*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Rendimiento</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">80% menos emisiones comparado con el modelo original de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción del costo operativo en un 70% respecto al modelo de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción en frecuencia y costo de mantenimiento en comparación con los karts de combustión.</span>
                    </li>
                  </ul>

                  {/*Botón Cotizar Móvil*/}
                  <div className="flex justify-center md:hidden">
                    <Link to="/contacto">
                      <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                        Cotiza Aquí
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*FIN SECCION KARTS*/}    

          {/* SECCION MOTORES1*/}
          <div className='py-10 px-4'>

            <h1 className='text-center text-neutral-100 font-bold text-4xl md:py-10' data-aos="fade-right">Conversión de <span className='font-bold text-4xl md:text-5xl text-amarillo'>Motores fuera de borda</span></h1>
            

            <div className="max-w-6xl mx-auto sm:flex items-center pt-4">

              {/*Columna 1*/}
              <div className="sm:w-2/5">
                {/*Descripción*/}
                <div>
                  <h2 className='text-center text-neutral-100 font-bold text-3xl pt-10 md:py-10' data-aos="fade-right">Modelo <span className='font-bold text-5xl text-amarillo'>1</span></h2>
                  <p className='text-center max-w-lg mx-auto py-8 px-8 text-neutral-300 text-base' data-aos="fade-up"> La conversión de ElectroRiders transforma motores fuera de borda de gasolina en vehículos eléctricos de alto rendimiento, optimizados para la eficiencia energética y la reducción de emisiones.</p>
                </div>
                {/*Imagen*/}
                <div className="px-10 md:px-5">
                  <img src={motor_borda1} alt="Album" className="w-full md:w-200 hover:scale-105 transition-transform duration-300" data-aos="zoom-in" />
                </div>
                {/*Botón Cotizar Laptop*/}
                <div className="justify-center hidden md:flex">
                  <Link to="/contacto">
                    <button className="btn text-xl mt-8 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                      Cotiza Aquí
                    </button>
                  </Link>
                </div>
              </div>

              {/*Columna 2*/}
              <div className="sm:w-3/5 ">
                {/*Especificaciones*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Especificaciones</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300 text-base" data-aos="fade-up">
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Motor Eléctrico:</span> <span className="value">20 kW de potencia.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Batería:</span> <span className="value">Litio-ion, 2.6 kWh capacidad total.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Autonomía:</span> <span className="value">Hasta 1 hora por carga completa.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Tiempo de Carga:</span> <span className="value">3 horas (cargador estándar).</span>
                    </li>
                  </ul>
                </div>

                {/*Componentes*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Componentes Incluídos</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Batería Compact-e</span> 
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Controlador de motor eléctrico</span> 
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Cargador</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Motor BLDC</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Instrumentación digital con indicadores de estado de carga y autonomía</span>
                    </li>
                  </ul>
                </div>

                {/*Rendimiento*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Rendimiento</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">80% menos emisiones comparado con el modelo original de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción del costo operativo en un 70% respecto al modelo de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción en frecuencia y costo de mantenimiento en comparación con los motores fuera de borda de combustión.</span>
                    </li>
                  </ul>

                  {/*Botón Cotizar Móvil*/}
                  <div className="flex justify-center md:hidden">
                    <Link to="/contacto">
                      <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                        Cotiza Aquí
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*FIN SECCION MOTORES1*/}    

          {/* SECCION MOTORES2*/}
          <div className='py-10 px-4'>

            {/*
            <h1 className='text-center text-neutral-100 font-bold text-4xl py-10' data-aos="fade-right">Conversión de <span className='font-bold text-5xl text-amarillo'>Motores fuera de borda</span></h1>
            */}

            <div className="max-w-6xl mx-auto sm:flex items-center pt-4">

              {/*Columna 1*/}
              <div className="sm:w-2/5">
                {/*Descripción*/}
                <div>
                <h2 className='text-center text-neutral-100 font-bold text-3xl md:py-10' data-aos="fade-right">
            Modelo <span className='font-bold text-5xl text-amarillo'>2</span></h2>
                  <p className='text-center max-w-lg mx-auto py-8 px-8 text-neutral-300 text-base' data-aos="fade-up"> La conversión de <span className='text-amarillo'>ElectroRiders</span> transforma motores fuera de borda de gasolina en vehículos eléctricos de alto rendimiento, optimizados para la eficiencia energética y la reducción de emisiones.</p>
                </div>
                {/*Imagen*/}
                <div className="px-10 md:px-5">
                  <img src={motor_borda2} alt="Album" className="w-full md:w-200 hover:scale-105 transition-transform duration-300" data-aos="zoom-in" />
                </div>
                {/*Botón Cotizar Laptop*/}
                <div className="justify-center hidden md:flex">
                  <Link to="/contacto">
                    <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                      Cotiza Aquí
                    </button>
                  </Link>
                </div>
              </div>

              {/*Columna 2*/}
              <div className="sm:w-3/5 ">
                {/*Especificaciones*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Especificaciones</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300 text-base" data-aos="fade-up">
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Motor Eléctrico:</span> <span className="value">40 kW de potencia.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Batería:</span> <span className="value">Litio-ion, 2.6 kWh capacidad total.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Autonomía:</span> <span className="value">Hasta 1 hora por carga completa.</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Tiempo de Carga:</span> <span className="value">3 horas (cargador estándar).</span>
                    </li>
                  </ul>
                </div>

                {/*Componentes*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Componentes Incluídos</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Batería Compact-e</span> 
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Controlador de motor eléctrico</span> 
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Cargador</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Motor BLDC</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Instrumentación digital con indicadores de estado de carga y autonomía</span>
                    </li>
                  </ul>
                </div>

                {/*Rendimiento*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Rendimiento</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">80% menos emisiones comparado con el modelo original de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción del costo operativo en un 70% respecto al modelo de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción en frecuencia y costo de mantenimiento en comparación con los motores fuera de borda de combustión.</span>
                    </li>
                  </ul>
 
                  {/*Botón Cotizar Móvil*/}
                  <div className="flex justify-center md:hidden">
                    <Link to="/contacto">
                      <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                        Cotiza Aquí
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*FIN SECCION MOTORES2*/} 

          {/* SECCION BATERIAS */}
          <div className='py-10 px-4'>

            <h1 className='text-center text-neutral-100 font-bold text-5xl md:py-10' data-aos="fade-right">
              Baterías <span className='font-bold text-4xl text-neutral-100'>de </span><span className='font-bold text-4xl text-amarillo'>ElectroRiders</span></h1>

            <div className="max-w-6xl mx-auto sm:flex items-center pt-4">

              {/*Columna 1*/}
              <div className="sm:w-2/5">
                {/*Descripción*/}
                <div>
                  <p className='text-center max-w-lg mx-auto py-8 px-8 text-neutral-300 text-base' data-aos="fade-up"> ElectroRiders fabrica baterías de alto rendimiento, optimizadas para la eficiencia energética y la reducción de emisiones.</p>
                </div >
                {/*Imagen*/}
                <div className="px-10 md:px-5">
                  <img src={baterias} alt="Album" className="w-full md:w-200 hover:scale-105 transition-transform duration-300" data-aos="zoom-in" />
                </div>
                {/*Botón Cotizar Laptop*/}
                <div className="justify-center hidden md:flex">
                  <Link to="/contacto">
                    <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                      Cotiza Aquí
                    </button>
                  </Link>
                </div>
              </div>

              {/*Columna 2*/}
              <div className="sm:w-3/5 ">
                {/*Especificaciones*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Especificaciones</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300 text-base" data-aos="fade-up">
                    <li><i className="fas fa-bolt"></i>
                      <span className="label text-verde">Batería Large-e:</span> <span className="value">Eléctrico: 5.16 kWh capacidad total</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Tiempo de carga:</span> <span>6 horas (cargador estándar)</span>
                    </li>
                    <li className="pt-10 "><i className="fas fa-bolt"></i>
                      <span className="label text-verde">Batería Compact-e:</span> <span className="value">2.6 kHw capacidad total</span>
                    </li>
                    <li><i className="fas fa-bolt"></i>
                      <span className="label">Tiempo de Carga:</span> <span>3 horas (cargador estándar).</span>
                    </li>
                  </ul>
                </div>

                {/*Componentes*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white" data-aos="fade-up">Componentes Incluídos</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Cargador</span>
                    </li>
                    <li><span className="material-symbols-outlined">manufacturing</span>
                      <span className="value">Instrumentación digital con indicadores de estado de carga y autonomía</span>
                    </li>
                  </ul>
                </div>

                {/*Rendimiento*/ }       
                <div>
                  <h1 className="text-left text-3xl max-w-lg mx-auto py-4 px-8 text-white"    data-aos="fade-up">Rendimiento</h1>
                  <ul className="text-left max-w-lg mx-auto py-4 px-8 text-neutral-300" data-aos="fade-up">
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">80% menos emisiones comparado con el modelo original de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción del costo operativo en un 70% respecto al modelo de combustión.</span> 
                    </li>
                    <li><span className="material-symbols-outlined">monitoring</span>
                      <span className="value">Reducción en frecuencia y costo de mantenimiento en comparación con otras baterías.</span>
                    </li>
                  </ul>

                  {/*Botón Cotizar Móvil*/}
                  <div className="flex justify-center md:hidden">
                    <Link to="/contacto">
                      <button className="btn text-xl mt-14 border-1 border-verde text-verde bg-transparent hover:bg-verde hover:text-negro font-bold py-2 px-4 rounded-none">
                        Cotiza Aquí
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*FIN SECCION BATERIAS*/}    

        </div>

        
      </div>

    </div>
    {/* FIN CONTENIDO */}

    <WhatsAppButton 
      showScrollBtn={showScrollBtn}
      isButtonAbsolute={isButtonAbsolute}
    /> 

    <div>
      <Footer />
    </div>
      
    {showScrollBtn && (
        <button
          style={{
            position: isButtonAbsolute ? 'absolute' : 'fixed',
            bottom: isButtonAbsolute ? '20px' : '70px',
            right: '10px'
          }}
          className="rounded-full bg-verde  hover:bg-amarillo text-white w-12 h-12 flex items-center justify-center"
          onClick={scrollToNextSection}>
          <span className="material-icons">keyboard_double_arrow_up</span>
        </button>
      )}
    </>
  )
}

export default Home
